var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var CommunicationService = (function () {
    function CommunicationService($rootScope, $q, $http, SCConfiguration, UserService, CHAT_SOCKET_HOST, ENV, Paginator, LeakyMapFactory, RichTextProcessor, CommunicationMemberCache, ConnectionStatus, WakeUpTimer, $window) {
        var _this = this;
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.$http = $http;
        this.SCConfiguration = SCConfiguration;
        this.UserService = UserService;
        this.CHAT_SOCKET_HOST = CHAT_SOCKET_HOST;
        this.ENV = ENV;
        this.Paginator = Paginator;
        this.LeakyMapFactory = LeakyMapFactory;
        this.RichTextProcessor = RichTextProcessor;
        this.CommunicationMemberCache = CommunicationMemberCache;
        this.ConnectionStatus = ConnectionStatus;
        this.WakeUpTimer = WakeUpTimer;
        this.Phoenix = $window.Phoenix;
        this.state = {};
        this.initializationDefer = $q.defer();
        this.$initializationPromise = this.initializationDefer.promise;
        this.loggingEnabled = ENV === 'development';
        this.WakeUpTimer.onWakeUp(function () { return _this.handleComputerWakeUp(); });
        this.ConnectionStatus.onChange(function (isOnline) { return _this.handleConnectionStatusChange(isOnline); });
    }
    CommunicationService.prototype.init = function () {
        var _this = this;
        this.user = this.UserService.getUser();
        var token = this.UserService.getToken();
        this.typingLeakyState = this.LeakyMapFactory.createWithTTL(3000, function (key, state) {
            _this.state.typingUsers[key] = state;
        });
        var socket = new this.Phoenix.Socket("".concat(this.CHAT_SOCKET_HOST, "/socket"), {
            params: { token: token },
            timeout: 3000,
            logger: function (kind, msg, data) {
                _this.loggingEnabled && console.log("".concat(kind, ":").concat(msg), data);
            },
        });
        socket.connect();
        socket.onOpen(function (ev) {
            _this.state.isReady = true;
            _this.loggingEnabled && console.log('OPEN', ev);
            var existingJoinedChannels = _this.socket.channels && _this.socket.channels.filter(function (ch) { return ch.state === 'joined'; });
            if (existingJoinedChannels && existingJoinedChannels.length) {
                existingJoinedChannels.forEach(function (ch) { return ch.rejoin(); });
            }
        });
        socket.onError(function (ev) {
            _this.state.isReady = false;
            _this.loggingEnabled && console.log('ERROR', ev);
        });
        socket.onClose(function (e) {
            _this.state.isReady = false;
            _this.loggingEnabled && console.log('CLOSE', e);
        });
        this.socket = socket;
        this.fetchInboxStats()
            .then(function (inboxStats) {
            _this.initState(inboxStats);
            var inboxKeys = _.keys(inboxStats);
            _this.generalEventChannel = _this.findChannelAndJoinIfNeeded('event:general', null);
            _this.generalEventChannel.on('presence_state', function (state) {
                _this.$rootScope.$apply(function () {
                    _this.state.presences = _this.Phoenix.Presence.syncState(_this.state.presences, state);
                });
            });
            _this.generalEventChannel.on('presence_diff', function (state) {
                _this.$rootScope.$apply(function () {
                    _this.state.presences = _this.Phoenix.Presence.syncDiff(_this.state.presences, state);
                });
            });
            _this.userEventChannel = _this.findChannelAndJoinIfNeeded('event:general:' + _this.user._id, function () { return _this.$rootScope.$apply(); });
            _this.userEventChannel.off('channel_created');
            _this.userEventChannel.off('channel_rejoined');
            _this.userEventChannel.off('new_message');
            _this.userEventChannel.off('new_member');
            _this.userEventChannel.off('member_left');
            _this.userEventChannel.off('message_read');
            _this.userEventChannel.on('channel_created', function (payload) {
                return _this.newChannelCallback(payload, null);
            });
            _this.userEventChannel.on('channel_rejoined', function (payload) {
                return _this.channelRejoinedCallback(payload, null);
            });
            _this.userEventChannel.on('new_message', function (payload) {
                return _this.newMessageCallback(payload, null);
            });
            _this.userEventChannel.on('new_member', function (payload) { return _this.newMemberCallback(payload, null); });
            _this.userEventChannel.on('member_left', function (payload) {
                return _this.memberLeftCallback(payload, null);
            });
            _this.userEventChannel.on('message_read', function (payload) {
                return _this.messageReadFromOtherDeviceCallback(payload, null);
            });
            _this.organizationEventChannels = inboxKeys
                .filter(function (inboxKey) { return inboxKey.indexOf('org:') > -1; })
                .map(function (inboxKey) {
                var organizationId = inboxKey.split(':')[1];
                var organizationEventChannel = _this.findChannelAndJoinIfNeeded('event:organization:' + organizationId, function () { return _this.$rootScope.$apply(); });
                organizationEventChannel.off('channel_created');
                organizationEventChannel.off('channel_rejoined');
                organizationEventChannel.off('new_message');
                organizationEventChannel.off('new_member');
                organizationEventChannel.off('member_left');
                organizationEventChannel.off('message_read');
                organizationEventChannel.on('channel_created', function (payload) {
                    return _this.newChannelCallback(payload, organizationId);
                });
                organizationEventChannel.on('channel_rejoined', function (payload) {
                    return _this.channelRejoinedCallback(payload, organizationId);
                });
                organizationEventChannel.on('new_message', function (payload) {
                    return _this.newMessageCallback(payload, organizationId);
                });
                organizationEventChannel.on('new_member', function (payload) {
                    return _this.newMemberCallback(payload, organizationId);
                });
                organizationEventChannel.on('member_left', function (payload) {
                    return _this.memberLeftCallback(payload, organizationId);
                });
                organizationEventChannel.on('message_read', function (payload) {
                    return _this.messageReadFromOtherDeviceCallback(payload, null);
                });
                return organizationEventChannel;
            });
            _this.initializationDefer.resolve();
        })
            .catch(angular.noop);
    };
    CommunicationService.prototype.initState = function (inboxStats) {
        var inboxes = _.mapValues(inboxStats, function () { return []; });
        this.state.inboxes = Immutable.fromJS(inboxes);
        this.state.inboxUnreadCount = inboxStats;
        this.state.currentInbox = '';
        this.state.currentChannel = null;
        this.state.channelMessages = {};
        this.state.hasMoreMessages = {};
        this.state.unreadCount = Immutable.fromJS(_.mapValues(inboxStats, function () { return ({}); }));
        this.state.readReceipts = {};
        this.state.typingUsers = {};
        this.state.presences = {};
        this.state.isReady = true;
    };
    CommunicationService.prototype.registerUploader = function ($flowInstance) {
        this.$flowUploaderInstance = $flowInstance;
    };
    CommunicationService.prototype.handleComputerWakeUp = function () {
        var _this = this;
        if (this.loggingEnabled)
            console.log('>>> handleComputerWakeUp');
        this.state.isReady = false;
        if (this.socket) {
            this.socket.disconnect(function () { return _this.socket.connect(); });
        }
    };
    CommunicationService.prototype.handleConnectionStatusChange = function (isOnline) {
        if (this.loggingEnabled)
            console.log('>>> handleConnectionStatusChange', isOnline);
        if (isOnline) {
            if (this.socket) {
                this.socket.connect();
            }
        }
        else {
            this.socket.disconnect();
        }
    };
    CommunicationService.prototype.destroy = function () {
        this.state = {};
        if (this.socket) {
            this.socket.disconnect();
        }
    };
    Object.defineProperty(CommunicationService.prototype, "isReady", {
        get: function () {
            return (this.$initializationPromise.$$state.status === 1 &&
                this.state.isReady &&
                this.ConnectionStatus.state.isOnline);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommunicationService.prototype, "hasInboxUnread", {
        get: function () {
            var _this = this;
            return (this.state.inboxUnreadCount &&
                Object.keys(this.state.inboxUnreadCount).some(function (key) { return !!_this.state.inboxUnreadCount[key]; }));
        },
        enumerable: false,
        configurable: true
    });
    CommunicationService.prototype.selectInbox = function (inboxId) {
        this.state.currentInbox = inboxId === 'private' ? this.getPrivateInboxKey() : "org:".concat(inboxId);
    };
    CommunicationService.prototype.selectChannel = function (channel) {
        this.state.currentChannel = channel;
    };
    CommunicationService.prototype.getPrivateInboxKey = function () {
        return "user:".concat(this.user._id);
    };
    CommunicationService.prototype.getState = function () {
        return this.state;
    };
    CommunicationService.prototype.getChannel = function (channelId, params) {
        var channelLocations = this.findChannelLocation(channelId);
        if (channelLocations.length) {
            var _a = channelLocations[0], inboxKey = _a.inboxKey, index = _a.index;
            return this.$q.resolve(this.state.inboxes.get(inboxKey).get(index).toJS());
        }
        return this.fetchChannel(channelId, params);
    };
    CommunicationService.prototype.refreshInboxStats = function () {
        var _this = this;
        if (this.inboxStatsLastFetchAt && Date.now() - this.inboxStatsLastFetchAt < 5000) {
            return;
        }
        this.fetchInboxStats()
            .then(function (inboxStats) {
            _this.state.inboxUnreadCount = inboxStats;
        })
            .catch(angular.noop);
    };
    CommunicationService.prototype.fetchInboxStats = function () {
        this.inboxStatsLastFetchAt = Date.now();
        var url = this.SCConfiguration.getEndpoint() + '/api/communication/inbox/stats';
        return this.$http.get(url).then(function (response) { return response.data; });
    };
    CommunicationService.prototype.fetchChannel = function (channelId, params) {
        var _this = this;
        var url = this.SCConfiguration.getEndpoint() + '/api/communication/channels/' + channelId;
        return this.$http.get(url, { params: params }).then(function (response) {
            if (response && response.data) {
                _this.CommunicationMemberCache.update([response.data]);
                return response.data;
            }
        });
    };
    CommunicationService.prototype.getInboxes = function () {
        return this.state.inboxes.toJS();
    };
    CommunicationService.prototype.getChannelMessages = function (channelId) {
        return this.state.channelMessages[channelId]
            ? this.state.channelMessages[channelId].toJS()
            : [];
    };
    CommunicationService.prototype.findMessageChannel = function (channelId) {
        return this.findChannelAndJoinIfNeeded("channel:".concat(channelId));
    };
    CommunicationService.prototype.findEventChanel = function (inboxKey) {
        if (inboxKey.indexOf('org:') > -1) {
            var organizationId = inboxKey.split(':')[1];
            return this.findChannelAndJoinIfNeeded("event:organization:".concat(organizationId));
        }
        return this.userEventChannel;
    };
    CommunicationService.prototype.findChannelAndJoinIfNeeded = function (topic, joinCallback) {
        if (joinCallback === void 0) { joinCallback = angular.noop; }
        if (!this.socket) {
            console.error('no socket connection. please connect ....');
            return;
        }
        var channel = _.find(this.socket.channels, function (ch) { return ch.topic === topic; });
        if (!channel) {
            channel = this.socket.channel(topic, {});
        }
        if (channel.state === 'closed') {
            channel
                .join()
                .receive('ok', function (payload) {
                if (_.isFunction(joinCallback)) {
                    joinCallback(null, payload);
                }
            })
                .receive('error', function (err) {
                joinCallback(err, null);
            });
        }
        return channel;
    };
    CommunicationService.prototype.findChannelLocation = function (channelId) {
        var locations = [];
        this.state.inboxes.entrySeq().forEach(function (_a) {
            var key = _a[0], channels = _a[1];
            var idx = channels.findIndex(function (ch) { return "".concat(ch.get('id')) === "".concat(channelId); });
            if (idx > -1) {
                locations.push({
                    inboxKey: key,
                    index: idx,
                });
            }
        });
        return locations;
    };
    CommunicationService.prototype.putChannels = function (inboxKey, payload, shouldConcat) {
        var _this = this;
        if (payload === void 0) { payload = []; }
        if (!this.isReady) {
            throw new Error('putChannels is not allow prior to initialization');
        }
        var channels = Immutable.fromJS(payload);
        if (shouldConcat) {
            var inboxChannels = this.state.inboxes.get(inboxKey);
            this.state.inboxes = this.state.inboxes.set(inboxKey, inboxChannels.concat(channels));
        }
        else {
            this.state.inboxes = this.state.inboxes.set(inboxKey, channels);
        }
        payload.forEach(function (channel) {
            return (_this.state.unreadCount = _this.state.unreadCount.setIn([inboxKey, channel.id], channel.unread_count || 0));
        });
        return channels;
    };
    CommunicationService.prototype.subscribeToChannel = function (channelId) {
        this.userEventChannel.push('add_channel', { channel_id: channelId });
    };
    CommunicationService.prototype.putMessages = function (channelId, messages, hasMore) {
        var _this = this;
        var channelMessages = this.state.channelMessages[channelId] || Immutable.List([]);
        this.state.channelMessages[channelId] = channelMessages.unshift.apply(channelMessages, messages.map(function (msg) { return _this.RichTextProcessor.process(msg); }));
        this.state.hasMoreMessages[channelId] = !!hasMore;
        return messages;
    };
    CommunicationService.prototype.addMessage = function (channelId, message) {
        this.state.channelMessages[channelId] =
            this.state.channelMessages[channelId] || Immutable.List([]);
        this.state.channelMessages[channelId] = this.state.channelMessages[channelId].push(this.RichTextProcessor.process(message));
    };
    CommunicationService.prototype.removeMessage = function (channelId, message) {
        this.state.channelMessages[channelId] =
            this.state.channelMessages[channelId] || Immutable.List([]);
        this.state.channelMessages[channelId] = this.state.channelMessages[channelId].filter(function (msg) {
            return message.client_uuid ? msg.client_uuid !== message.client_uuid : msg.id === message.id;
        });
    };
    CommunicationService.prototype.markChannelAsRead = function (inboxKey, channelId) {
        var currentUnreadCount = this.state.unreadCount.getIn([inboxKey, channelId]);
        if (currentUnreadCount) {
            this.decreaseInboxUnreadCount(inboxKey);
        }
        this.state.unreadCount = this.state.unreadCount.setIn([inboxKey, channelId], 0);
    };
    CommunicationService.prototype.increaseInboxUnreadCount = function (inboxKey) {
        var currentCount = this.state.inboxUnreadCount[inboxKey] || 0;
        this.state.inboxUnreadCount[inboxKey] = currentCount + 1;
    };
    CommunicationService.prototype.decreaseInboxUnreadCount = function (inboxKey) {
        var currentCount = this.state.inboxUnreadCount[inboxKey] || 0;
        if (currentCount > 0) {
            this.state.inboxUnreadCount[inboxKey] = currentCount - 1;
        }
    };
    CommunicationService.prototype.initChannel = function (channel) {
        var _this = this;
        var socketChannel = this.findChannelAndJoinIfNeeded("channel:".concat(channel.get('id')), function (err, payload) {
            if (err) {
                throw new Error(err);
            }
            else {
                _this.state.channelMessages[channel.get('id')] = Immutable.List([]);
                _this.putMessages(channel.get('id'), payload.messages, payload.has_more);
                _this.state.readReceipts[channel.get('id')] = Immutable.List(payload.read_receipts || []);
                _this.state.isReady = true;
                _this.$rootScope.$apply();
            }
        });
        socketChannel.off('typing_start');
        socketChannel.off('typing_stop');
        socketChannel.on('typing_start', function (payload) { return _this.typingStartCallback(payload); });
        socketChannel.on('typing_stop', function (payload) { return _this.typingStopCallback(payload); });
        socketChannel.off('message_read');
        socketChannel.on('message_read', function (payload) { return _this.messageReadCallback(payload); });
        return socketChannel;
    };
    CommunicationService.prototype.newChannelCallback = function (payload, organizationId) {
        var _this = this;
        this.$rootScope.$apply(function () {
            var inboxKey = organizationId ? "org:".concat(organizationId) : "user:".concat(_this.user._id);
            _this.addChannelToInbox(payload, inboxKey, true);
        });
    };
    CommunicationService.prototype.channelRejoinedCallback = function (payload, organizationId) {
        var _this = this;
        var params = {};
        if (organizationId) {
            params.organization_id = organizationId;
        }
        return this.getChannel(payload.channel_id, params).then(function (channel) {
            var inboxKey = organizationId ? "org:".concat(organizationId) : "user:".concat(_this.user._id);
            _this.addChannelToInbox(channel, inboxKey, true);
        });
    };
    CommunicationService.prototype.getInboxKeys = function (channel) {
        var inboxKeys = [];
        var userId = this.user._id;
        var userOrganizationIds = this.user.accounts
            .filter(function (acc) { return acc.type && acc.type !== 'user'; })
            .map(function (acc) { return acc._id; });
        (channel.organizations || []).forEach(function (org) {
            if (userOrganizationIds.indexOf(org.id) > -1) {
                inboxKeys.push("org:".concat(org.id));
            }
        });
        (channel.users || []).forEach(function (user) {
            if (user.id === userId) {
                inboxKeys.push("user:".concat(user.id));
            }
        });
        return inboxKeys;
    };
    CommunicationService.prototype.addChannelToInbox = function (channel, inboxKey, isNewChannel) {
        var immutableChannel = Immutable.fromJS(channel);
        var inboxChannels = this.state.inboxes.get(inboxKey);
        var index = inboxChannels.findIndex(function (ch) { return ch.get('id') === immutableChannel.get('id'); });
        if (index > -1) {
            this.state.inboxes = this.state.inboxes.set(inboxKey, inboxChannels.set(index, immutableChannel));
        }
        else {
            this.state.inboxes = this.state.inboxes.set(inboxKey, inboxChannels.unshift(immutableChannel));
        }
        var _a = inboxKey.split(':'), inboxType = _a[0], inboxId = _a[1];
        this.state.unreadCount = this.state.unreadCount.setIn([inboxKey, channel.id], channel.unread_count);
        if (isNewChannel) {
            if (channel.unread_count) {
                this.increaseInboxUnreadCount(inboxKey);
            }
            var eventChannel = this.findEventChanel(inboxKey);
            eventChannel.push('add_channel', { channel_id: channel.id });
        }
        return immutableChannel;
    };
    CommunicationService.prototype.removeChannelFromInbox = function (channelId, inboxKey) {
        var inboxChannels = this.state.inboxes.get(inboxKey);
        this.state.inboxes = this.state.inboxes.set(inboxKey, inboxChannels.filter(function (ch) { return ch.get('id') !== channelId; }));
        if (this.state.unreadCount.getIn([inboxKey, channelId]) > 0) {
            this.decreaseInboxUnreadCount(inboxKey);
        }
        this.state.unreadCount = this.state.unreadCount.setIn([inboxKey, channelId], 0);
        this.state.channelMessages[channelId] = null;
        var eventChannel = this.findEventChanel(inboxKey);
        eventChannel.push('remove_channel', { channel_id: channelId });
    };
    CommunicationService.prototype.newMessageCallback = function (payload, organizationId) {
        var _this = this;
        var channelId = payload.channel_id;
        var channelLocations = this.findChannelLocation(channelId);
        if (!channelLocations.length) {
            var params = {};
            if (organizationId) {
                params.organization_id = organizationId;
            }
            return this.getChannel(payload.channel_id, params).then(function (channel) {
                var inboxKey = organizationId ? "org:".concat(organizationId) : "user:".concat(_this.user._id);
                _this.addChannelToInbox(channel, inboxKey, false);
                _this.refreshInboxStats();
                _this.$rootScope.$broadcast('communication:message:received', {
                    message: payload,
                    inboxKey: inboxKey,
                });
            });
        }
        var channelMessages = this.state.channelMessages[channelId] || Immutable.List([]);
        var isSystemMessage = payload.type === 0;
        var existing = channelMessages.find(function (msg) {
            return msg.id === payload.id || (payload.client_uuid && msg.client_uuid === payload.client_uuid);
        });
        if (!existing) {
            this.state.channelMessages[channelId] = channelMessages.push(this.RichTextProcessor.process(payload));
            channelLocations.forEach(function (_a) {
                var inboxKey = _a.inboxKey;
                var _b = inboxKey.split(':'), inboxType = _b[0], id = _b[1];
                var isOwnMessage = payload.organization_id
                    ? payload.organization_id === id
                    : payload.user_id === id;
                if (!isOwnMessage && !isSystemMessage) {
                    var currentUnreadCount = _this.state.unreadCount.getIn([inboxKey, channelId], 0);
                    if (currentUnreadCount === 0) {
                        _this.increaseInboxUnreadCount(inboxKey);
                    }
                    _this.state.unreadCount = _this.state.unreadCount.setIn([inboxKey, channelId], currentUnreadCount + 1);
                }
            });
        }
        if (!isSystemMessage) {
            this.updateChannelLastMessage(channelId, payload);
        }
        this.$rootScope.$broadcast('communication:message:received', {
            message: payload,
            inboxKey: _.get(channelLocations[0], 'inboxKey'),
        });
    };
    CommunicationService.prototype.newMemberCallback = function (payload, organizationId) {
        var _this = this;
        var params = {};
        if (organizationId) {
            params.organization_id = organizationId;
        }
        return this.fetchChannel(payload.channel_id, params).then(function (channel) {
            var inboxKey = organizationId ? "org:".concat(organizationId) : "user:".concat(_this.user._id);
            _this.addChannelToInbox(channel, inboxKey, false);
        });
    };
    CommunicationService.prototype.memberLeftCallback = function (payload, _organizationId) {
        var _this = this;
        var channelLocations = this.findChannelLocation(payload.channel_id);
        if (channelLocations.length) {
            channelLocations.forEach(function (_a) {
                var inboxKey = _a.inboxKey, index = _a.index;
                var inboxChannels = _this.state.inboxes.get(inboxKey);
                inboxChannels = inboxChannels.update(index, function (channel) {
                    if (payload.user_id) {
                        channel = channel.set('users', channel.get('users').filter(function (user) { return user.get('id') !== payload.user_id; }));
                    }
                    if (payload.organization_id) {
                        channel = channel.set('organizations', channel
                            .get('organizations')
                            .filter(function (org) { return org.get('id') !== payload.organization_id; }));
                    }
                    return channel;
                });
                _this.state.inboxes = _this.state.inboxes.set(inboxKey, inboxChannels);
            });
        }
    };
    CommunicationService.prototype.updateChannelLastMessage = function (channelId, payload) {
        this.updateChannel(channelId, {
            last_message: payload.text,
            last_message_ts: payload.ts,
        });
    };
    CommunicationService.prototype.updateChannel = function (channelId, payload) {
        var _this = this;
        var channelLocations = this.findChannelLocation(channelId);
        if (channelLocations.length) {
            channelLocations.forEach(function (_a) {
                var inboxKey = _a.inboxKey, index = _a.index;
                var inboxChannels = _this.state.inboxes.get(inboxKey);
                inboxChannels = inboxChannels.update(index, function (channel) {
                    channel = channel.merge(payload);
                    return channel;
                });
                _this.state.inboxes = _this.state.inboxes.set(inboxKey, inboxChannels);
            });
        }
    };
    CommunicationService.prototype.typingStartCallback = function (payload) {
        this.typingLeakyState.add(payload.channel_id, payload);
        this.$rootScope.$broadcast('communication:message:typing_start', payload);
    };
    CommunicationService.prototype.typingStopCallback = function (payload) {
        this.typingLeakyState.remove(payload.channel_id, payload);
        this.$rootScope.$broadcast('communication:message:typing_stop', payload);
    };
    CommunicationService.prototype.messageReadCallback = function (payload) {
        var channelId = payload.channel_id;
        var channelReadReceipts = this.state.readReceipts[channelId] || Immutable.List([]);
        var idx = channelReadReceipts.findIndex(function (item) {
            return item.user_id === payload.user_id &&
                (payload.organization_id
                    ? item.organization_id === payload.organization_id
                    : !item.organization_id);
        });
        if (idx > -1) {
            this.state.readReceipts[channelId] = channelReadReceipts.update(idx, function (item) { return (__assign(__assign({}, item), payload)); });
        }
        else {
            this.state.readReceipts[channelId] = channelReadReceipts.push(payload);
        }
        this.$rootScope.$broadcast('communication:message:read', payload);
    };
    CommunicationService.prototype.messageReadFromOtherDeviceCallback = function (payload, organizationId) {
        var inboxKey = organizationId ? "org:".concat(organizationId) : "user:".concat(this.user._id);
        var channelUnreadCount = this.state.unreadCount.getIn([inboxKey, payload.channel_id], 0);
        if (channelUnreadCount) {
            this.state.unreadCount = this.state.unreadCount.setIn([inboxKey, payload.channel_id], 0);
            this.decreaseInboxUnreadCount(inboxKey);
        }
        this.$rootScope.$broadcast('communication:message:read', payload);
    };
    CommunicationService.prototype.clearDraftMessage = function (channelId) {
        return localStorage.removeItem("communication:draft:".concat(channelId));
    };
    CommunicationService.prototype.storeDraftMessage = function (channelId, message) {
        return localStorage.setItem("communication:draft:".concat(channelId), message);
    };
    CommunicationService.prototype.retrieveDraftMessage = function (channelId) {
        return localStorage.getItem("communication:draft:".concat(channelId));
    };
    return CommunicationService;
}());
angular.module('app.general').service('CommunicationService', CommunicationService);