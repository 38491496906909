(function () {
  'use strict';

  class UploadButtonController {
    private api;
    private token;
    private flowUploadOptions;

    private endpoint;
    private onCompleted;

    private files = [];

    constructor(API_END_POINT, UserService) {
      this.api = API_END_POINT;
      this.token = UserService.getToken();
    }

    $onInit() {
      this.flowUploadOptions = {
        target: this.api + this.endpoint,
        simultaneousUploads: 1,
        chunkSize: 5 * 1024 * 1024,
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      };
    }

    onFileSuccess($file, response) {
      const { file } = JSON.parse(response);
      this.files.push({ name: $file.name, path: file });
    }

    fireCompleted(files) {
      this.onCompleted({ $files: this.files });

      files.splice(0, files.length);
      this.files = [];
    }
  }

  angular.module('app.general').component('scUploadButton', {
    templateUrl: 'general/components/sc-upload-button.html',
    transclude: true,
    controller: UploadButtonController,

    bindings: {
      endpoint: '@',
      detailedMode: '<',
      onCompleted: '&',
    },
  });
})();
