var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
(function () {
    angular
        .module('app.general')
        .directive('scUserActionsDropdown', scUserActionsDropdownDirective)
        .controller('UserActionsDropdownController', UserActionsDropdownController);
    function scUserActionsDropdownDirective() {
        return {
            restrict: 'EA',
            templateUrl: 'general/views/scUserActionsDropdownDirectiveView.html',
            controller: 'UserActionsDropdownController',
            controllerAs: '$ctrl',
            scope: true,
        };
    }
    function UserActionsDropdownController($q, $scope, $window, UserService, $uibModal, VideoUsageTracker, SPORT_ID) {
        this.initialize = function () {
            this.user = UserService.getUser();
            this.account = UserService.getActiveAccount();
            this.activeSport = parseInt(localStorage.getItem('scActiveSport')) || SPORT_ID.HOCKEY;
        };
        this.signOut = signOut;
        this.equals = angular.equals;
        this.changeAccount = changeAccount;
        this.changeSport = changeSport;
        this.transformRoleName = function (role) {
            return role.display;
        };
        this.hasAccountFunctions = function () {
            return (this.account &&
                this.account.functions &&
                this.account.functions.filter(function (item) { return !!item; }).length > 0);
        };
        this.initialize();
        $scope.$on('userDataUpdated', this.initialize.bind(this));
        var vm = this;
        $q.all([UserService.getAvailableAccounts(), VideoUsageTracker.fetchUsagesWithAccounts()]).then(function (_a) {
            var availableAccounts = _a[0], accountsUsages = _a[1];
            vm.availableAccounts = availableAccounts.map(function (acc) {
                return __assign(__assign({}, acc), { usage: accountsUsages.find(function (usage) { return usage.account._id === acc._id; }) });
            });
        });
        function signOut() {
            UserService.logout();
        }
        function changeAccount(account) {
            if (vm.equals(vm.account, account)) {
                return;
            }
            var modalInstance = $uibModal.open({
                size: 'sm',
                template: '<busy-modal text="general.switching_account"/>',
                backdrop: 'static',
                keyboard: false,
            });
            UserService.switchToAccount(account)
                .then(function () {
                $window.location = '/platform/countries';
            })
                .catch(function () {
                modalInstance.close();
            });
        }
        function changeSport(sport) {
            if (vm.equals(vm.activeSport, sport.id)) {
                return;
            }
            var modalInstance = $uibModal.open({
                size: 'sm',
                template: '<busy-modal text="Loading ..."/>',
                backdrop: 'static',
                keyboard: false,
            });
            localStorage.setItem('scActiveSport', sport.id);
            $window.location = '/platform/countries';
        }
    }
})();