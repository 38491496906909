(function () {
  'use strict';

  function scLeagueLinkDirective() {
    return {
      restrict: 'E',
      scope: { league: '=' },
      templateUrl: 'general/views/scLeagueLink.html',
    };
  }

  angular.module('app.general').directive('scLeagueLink', scLeagueLinkDirective);
})();
