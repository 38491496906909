(function () {
  'use strict';

  angular.module('app.general').directive('scUiView', scUiViewDirective);

  // The problem (and feature in the same time) with original `ui-view` directive:
  // When we are switching between states, own views are being refreshed
  //
  // $stateProvider.state('player.facts', {
  //     url: '/facts',
  //     views: {
  //         'facts-subview@player':     { templateUrl: 'facts-subview.html'     },
  //         'transfers-subview@player': { templateUrl: 'transfers-subview.html' }
  //     }
  // })
  // $stateProvider.state('player.transfers', {
  //     url: '/transfers',
  //     views: {
  //         'transfers-subview@player': { templateUrl: 'transfers-subview.html' },
  //         'games-subview@player':     { templateUrl: 'fames-subview.html'     }
  //     }
  // })
  //
  // Here would be usefull not to refresh view 'transfers-subview@player', because both states have it
  // but when we switching from 'player.facts' to 'player.transfers' we will get the view refreshed
  //
  // This behavior would be usefull for `md-tabs` - we can preload next tab content
  function scUiViewDirective($compile, $controller, $animate, $state) {
    return {
      restrict: 'A',
      transclude: 'element',

      link: function ($scope, $element, $attr, $ctrl, $transclude) {
        var viewId = getUiViewName($scope, $attr, $element);
        var lastLocals;

        updateView();
        $scope.$on('$stateChangeSuccess', updateView);

        function updateView() {
          var current = $state.$current,
            locals = current && current.locals[viewId];

          if (locals && !lastLocals) {
            showContent(locals);
            lastLocals = locals;
          } else if (!locals && lastLocals && lastLocals.$scope) {
            hideContent(lastLocals);
            lastLocals = null;
          }
        }

        function hideContent(locals) {
          $animate.leave(locals.$element);
          locals.$scope.$destroy();

          locals.$scope = null;
          locals.$element = null;
        }

        function showContent(locals) {
          $transclude(function (innerElement) {
            (locals.$scope = $scope.$new()),
              (locals.$element = innerElement.append(locals.$template));
          });

          locals.$element.data('$uiView', { name: viewId, state: locals.$$state });

          if (locals.$$controller) {
            var controller = $controller(locals.$$controller, locals);
            if (locals.$$controllerAs) {
              locals.$scope[locals.$$controllerAs] = controller;
            }
          }

          $compile(locals.$element.children())(locals.$scope);
          $animate.enter(locals.$element, $element.parent(), $element);
        }
      },
    };
  }

  // This helper has been taken directly from `ui-router` library
  function getUiViewName(scope, attrs, element) {
    var name = attrs.scUiView || attrs.name || '';
    var inherited = element.inheritedData('$uiView');
    return name.indexOf('@') >= 0 ? name : name + '@' + (inherited ? inherited.state.name : '');
  }
})();
