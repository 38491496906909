(function () {
  'use strict';

  function AccountTitleController(SPORT_ID) {
    this.transformRoleName = function (role) {
      return role.display;
    };

    this.hasAccountFunctions = function () {
      return (
        this.account &&
        this.account.functions &&
        this.account.functions.filter((item) => !!item).length > 0
      );
    };

    this.hasHockey = function () {
      return this.account && this.account.sports?.some((iter) => iter.id === SPORT_ID.HOCKEY);
    };
    this.hasFootball = function () {
      return this.account && this.account.sports?.some((iter) => iter.id === SPORT_ID.FOOTBALL);
    };
  }

  angular.module('app.general').component('scAccountTitle', {
    templateUrl: 'general/components/sc-account-title.html',
    controller: AccountTitleController,
    bindings: { account: '<' },
  });
})();
