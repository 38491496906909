// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CacheReturnValue(scopeGetter) {
  return function (_target, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function () {
      const $scope = scopeGetter(this);
      if (!$scope.$$cache) {
        $scope.$$cache = {};
      }

      const cache = ($scope.$$cache[propertyKey] =
        $scope.$$cache[propertyKey] || new Map<string, any>());

      // eslint-disable-next-line prefer-rest-params
      const state = JSON.stringify(arguments);

      if (cache.has(state)) {
        return cache.get(state);
      }

      // eslint-disable-next-line prefer-rest-params
      const value = original.apply(this, arguments);

      cache.set(state, value);
      return value;
    };
  };
}
