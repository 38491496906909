class PlaylistsController {
  private user;
  private player;
  private team;
  private onPlay;

  private playlists;
  private $promise;
  private onPlaylistsLoaded: (params: { $playlists: any; $playlistsCount: number }) => void;

  constructor(
    private PlayerService,
    private TeamService,
    private GameService,
    private UserService,
    private Paginator,
  ) {
    this.user = UserService.getUser();
  }

  $onInit() {
    if (this.player) {
      this.$promise = this.PlayerService.getPlaylists(this.player._id).then((playlists) => {
        this.playlists = playlists;
        this.onPlaylistsLoaded({ $playlists: playlists, $playlistsCount: playlists.length });
      });
    } else if (this.team) {
      this.$promise = this.TeamService.getPlaylists(this.team._id).then((playlists) => {
        this.playlists = playlists;
        this.onPlaylistsLoaded({ $playlists: playlists, $playlistsCount: playlists.length });
      });
    }
  }

  play(list) {
    this.GameService.playTaggingList(list);
  }

  isOwn() {
    return (playlist) => {
      return playlist.createdBy.userId === this.user._id;
    };
  }

  isShared() {
    return (playlist) => {
      return (
        playlist.createdBy.userId !== this.user._id &&
        playlist.shareWith &&
        playlist.shareWith.length
      );
    };
  }
}

angular.module('app.general').component('playlistsFor', {
  templateUrl: 'general/components/playlists-for.html',
  controller: PlaylistsController,
  bindings: {
    player: '<',
    team: '<',
    onPlaylistsLoaded: '&',
  },
});
