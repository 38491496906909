class VideoUsageTracker {
  private reportingUrl;
  private accountsUsageUrl;
  private watchToken;
  private currentUsage;
  private trackingType;

  private usageByUrl;
  private downloadedByUrl;
  private reportedUsageByUrl;
  private lastTrackedTimeByUrl;
  private onSessionStarted;
  private onQuotaExceeded;
  private onDownloadExceeded;

  constructor(
    private $http,
    private SCConfiguration,
  ) {
    this.reportingUrl = this.SCConfiguration.getEndpoint() + '/api/usage';
    this.accountsUsageUrl = this.SCConfiguration.getEndpoint() + '/api/usage/accounts';
  }

  get canWatch() {
    return this.watchToken && this.checkLimit();
  }

  get canDownload() {
    return this.watchToken && this.currentUsage.canDownload && this.checkLimit();
  }

  get unlimitedUsage() {
    return this.watchToken && this.currentUsage.allow > 1000000000;
  }

  startNewSession(onSessionStarted, onQuotaExceeded, onDownloadExceeded) {
    this.trackingType = 'WATCH';
    this.usageByUrl = {};
    this.downloadedByUrl = {};
    this.reportedUsageByUrl = {};
    this.lastTrackedTimeByUrl = {};
    this.onSessionStarted = onSessionStarted;
    this.onQuotaExceeded = onQuotaExceeded;
    this.onDownloadExceeded = onDownloadExceeded;
    return this.fetchCurrentUsage()
      .then(() => this.onSessionStarted(null, this.canWatch, this.canDownload))
      .catch((err) => this.onSessionStarted(err, false, false));
  }

  startNewTaggingSession(onSessionStarted, onQuotaExceeded, onDownloadExceeded) {
    this.startNewSession(onSessionStarted, onQuotaExceeded, onDownloadExceeded);
    this.trackingType = 'TAGGING';
  }

  fetchCurrentUsage() {
    return this.$http
      .get(this.reportingUrl)
      .then((res) => res.data)
      .then((currentUsage) => {
        this.currentUsage = currentUsage;
        this.watchToken = currentUsage.watchToken;
        return currentUsage;
      });
  }

  track(videoUrl, currentTime) {
    if (!this.currentUsage) {
      return;
    }

    const canWatchPreviously = this.checkLimit();

    const lastTrackedTime = this.lastTrackedTimeByUrl[videoUrl];
    const reportedUsage = this.reportedUsageByUrl[videoUrl] || 0;

    if (lastTrackedTime) {
      const delta = currentTime - lastTrackedTime;
      if (delta > 0 && delta < 2) {
        this.usageByUrl[videoUrl] = this.usageByUrl[videoUrl] || 0;
        this.usageByUrl[videoUrl] += delta;
      }
    }

    this.lastTrackedTimeByUrl[videoUrl] = currentTime;

    const usageDelta = this.usageByUrl[videoUrl] - reportedUsage;
    if (usageDelta >= 5) {
      this.sendReport(videoUrl, this.usageByUrl[videoUrl]);
      this.reportedUsageByUrl[videoUrl] = this.usageByUrl[videoUrl];
    }

    const canWatchNow = this.checkLimit();
    if (!canWatchNow) {
      this.onQuotaExceeded(canWatchNow);
    }

    // send the final report when user use up some last seconds
    //
    // this is to prevent an edge case that the remaining usage is less than 5 seconds
    // and we only report usage every 5 seconds
    //
    // This might leads to the case user will never use up that 3 seconds left, and always
    // be able to view clip for 3 seconds then seeing the error message about video limit
    //
    if (canWatchPreviously && !canWatchNow) {
      this.sendReport(videoUrl, this.usageByUrl[videoUrl]);
    }
  }

  trackDownload(videoUrl, seconds) {
    this.downloadedByUrl[videoUrl] = this.downloadedByUrl[videoUrl] || 0;
    this.downloadedByUrl[videoUrl] += seconds;

    const canDownload = this.checkLimit();
    if (!canDownload) {
      this.onDownloadExceeded(canDownload);
    }
  }

  sendReport(videoUrl, usage) {
    const params: any = {
      watchToken: this.watchToken,
      videoUrl,
      usage,
    };

    if (this.trackingType) {
      params.type = this.trackingType;
    }

    this.$http.post(this.reportingUrl, params);
  }

  checkLimit() {
    // unlimited usage
    if (this.currentUsage.allow > 1000000000) {
      return true;
    }

    return this.getCurrentLimit() > 0;
  }

  getCurrentLimit() {
    let sessionTotalUsage = 0;

    _.forOwn(this.usageByUrl, (value, _key) => {
      sessionTotalUsage += value;
    });
    _.forOwn(this.downloadedByUrl, (value, _key) => {
      sessionTotalUsage += value;
    });

    return (
      this.currentUsage.allow -
      (this.currentUsage.used + this.currentUsage.downloaded + sessionTotalUsage)
    );
  }

  fetchUsagesWithAccounts() {
    return this.$http
      .get(this.accountsUsageUrl)
      .then((res) => res.data)
      .then((accountsUsage) =>
        accountsUsage.map((usage) => {
          let canWatch = false;
          // unlimited usage
          if (usage.allow > 1000000000) {
            canWatch = true;
          } else {
            canWatch = usage.allow - usage.used - usage.downloaded > 0;
          }

          return {
            ...usage,
            canWatch,
          };
        }),
      );
  }
}

angular.module('app.general').service('VideoUsageTracker', VideoUsageTracker);
