class ShareWithHierarchyOrganizationsController {
  readonly organizations;
  readonly selections;
  readonly fixedSelections;
  readonly excludeIds;
  readonly disableSelectAll;
  private onSelectionChange;

  private suggestions;

  constructor(private UserService) {}

  $onInit() {
    this.suggestions = this.organizations.map((org) => {
      return {
        _id: org._id,
        display: org.name,
        type: 'organization',
      };
    });
  }

  select($selection) {
    if (!this.isSelected($selection)) {
      const selections = (this.selections || []).concat([$selection]);
      this.onSelectionChange({ $selections: selections });
    }
  }

  unselect($selection, $index) {
    const selections = (this.selections || []).filter((item, index) => index !== $index);
    this.onSelectionChange({ $selections: selections });
  }

  isSelected(item) {
    return (this.selections || []).some((selection) => selection._id === item._id);
  }
}

angular.module('app.general').component('shareWithHierarchyOrganizations', {
  templateUrl: 'general/components/sharing/share-with-hierarchy-organizations.html',
  controller: ShareWithHierarchyOrganizationsController as any,
  bindings: {
    organizations: '<',
    selections: '<',
    fixedSelections: '<',
    excludeIds: '<',
    disableSelectAll: '<',
    placeholder: '@',
    onSelectionChange: '&?',
  },
});
