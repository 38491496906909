'use strict';

class ScTagsAutocomplete {
  private searchText;
  private isMenuOpen;

  private placeholder;
  private selections;
  private fixedSelections;
  private closeMenuOnSelect;
  private onSelect;
  private onUnselect;
  private onSearchTextChange;
  private exposedAs;
  private openMenuOnFocus;
  private autoFocus;

  constructor(
    private $element,
    private $scope,
  ) {
    this.searchText = '';
  }

  $onInit() {
    if (this.exposedAs && this.exposedAs.namespace) {
      const ns = this.exposedAs.namespace;
      this.$scope.$parent[ns] = {};
      this.exposedAs.apis.forEach((api) => {
        if (typeof this[api] === 'function') {
          this.$scope.$parent[ns][api] = this[api].bind(this);
        } else {
          this.$scope.$parent[ns][api] = this[api];
        }
      });
    }
    this.closeMenuOnSelect =
      typeof this.closeMenuOnSelect === 'undefined' ? true : this.closeMenuOnSelect;
  }

  $postLink() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.focusToInput();
      }, 200);
    }
  }

  focusToInput() {
    this.$element.find('.sc-tags-input').focus();
    if (this.openMenuOnFocus) {
      this.isMenuOpen = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleBlur($event) {
    // requestAnimationFrame(() => this.searchText = '');
    this.isMenuOpen = false;
  }

  close() {
    this.searchText = '';
    this.onSearchTextChange({ $text: '' });
    this.isMenuOpen = false;
  }

  select($suggestion) {
    if (this.closeMenuOnSelect) {
      this.searchText = '';
    }
    this.onSelect({ $suggestion });
    this.focusToInput();
  }

  isMenuVisible() {
    return this.isMenuOpen || Boolean(this.searchText);
  }

  isFixed(selection) {
    return (this.fixedSelections || []).indexOf(selection) > -1;
  }
}

angular.module('app.general').component('scTagsAutocomplete', {
  templateUrl: 'general/components/sc-tags-autocomplete.html',
  controller: ScTagsAutocomplete,
  controllerAs: '$scTagsCtrl',
  transclude: true,
  bindings: {
    placeholder: '<',
    displayProperty: '@',
    selections: '<',
    fixedSelections: '<',
    closeMenuOnSelect: '<',
    openMenuOnFocus: '<',
    autoFocus: '<',
    customTagItemCssClass: '&?',
    onSelect: '&?',
    onUnselect: '&?',
    onSearchTextChange: '&?',
    exposedAs: '<',
  },
});
