'use strict';
var NotificationCenter = (function () {
    function NotificationCenter(PageVisibility, NativeNotificationBackend, MaterialNotificationBackend) {
        this.PageVisibility = PageVisibility;
        this.backends = {
            native: NativeNotificationBackend,
            material: MaterialNotificationBackend,
        };
        this.audioElement = document.createElement('audio');
        this.audioElement.src = '/assets/sounds/notification.ogg';
    }
    Object.defineProperty(NotificationCenter.prototype, "native", {
        get: function () {
            return this.backends.native;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationCenter.prototype, "material", {
        get: function () {
            return this.backends.material;
        },
        enumerable: false,
        configurable: true
    });
    NotificationCenter.prototype.playSound = function () {
        this.audioElement.play();
    };
    NotificationCenter.prototype.showText = function (title, content) {
        var backend = this.chooseBackend();
        return backend.showText(title, content);
    };
    NotificationCenter.prototype.show = function (params) {
        var backend = this.chooseBackend();
        return backend.show(params);
    };
    NotificationCenter.prototype.chooseBackend = function () {
        if (this.native.isEnabled &&
            (!this.PageVisibility.state || !this.PageVisibility.state.isVisible)) {
            return this.native;
        }
        else {
            return this.material;
        }
    };
    return NotificationCenter;
}());
var NativeNotificationBackend = (function () {
    function NativeNotificationBackend($q, $rootScope) {
        this.$q = $q;
        this.$rootScope = $rootScope;
        this.handler = window.Notification;
    }
    Object.defineProperty(NativeNotificationBackend.prototype, "isSupported", {
        get: function () {
            return !!this.handler;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NativeNotificationBackend.prototype, "isEnabled", {
        get: function () {
            return this.isSupported && this.handler.permission === 'granted';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NativeNotificationBackend.prototype, "isDenied", {
        get: function () {
            return this.isSupported && this.handler.permission === 'denied';
        },
        enumerable: false,
        configurable: true
    });
    NativeNotificationBackend.prototype.requestPermissions = function () {
        var _this = this;
        this.handler.requestPermission(function () {
            _this.$rootScope.$digest();
        });
    };
    NativeNotificationBackend.prototype.showText = function (title, content) {
        var notification = new this.handler(title, { body: content });
        return new this.$q(function (resolve) {
            notification.onclick = function (_event) {
                resolve();
                window.focus();
                notification.close();
            };
        });
    };
    NativeNotificationBackend.prototype.show = function (params) {
        var notification = new this.handler(params.title, {
            body: params.text,
            image: params.image,
            icon: params.avatar,
        });
        return new this.$q(function (resolve) {
            notification.onclick = function (_event) {
                resolve();
                window.focus();
                notification.close();
            };
        });
    };
    return NativeNotificationBackend;
}());
var MaterialNotificationBackend = (function () {
    function MaterialNotificationBackend($q, $mdToast) {
        this.$q = $q;
        this.$mdToast = $mdToast;
    }
    MaterialNotificationBackend.prototype.showText = function (title, content) {
        var _this = this;
        this.toast = this.$mdToast.show(this.$mdToast
            .scNotification()
            .title(title)
            .content(content)
            .resolve(function () { return _this.onClick(); }));
        return new this.$q(function (resolve) { return (_this.resolve = resolve); });
    };
    MaterialNotificationBackend.prototype.show = function (params) {
        var _this = this;
        this.toast = this.$mdToast.show(this.$mdToast
            .scNotification()
            .title(params.title)
            .content(params.text)
            .image(params.image)
            .icon(params.avatar)
            .resolve(function () { return _this.onClick(); }));
        return new this.$q(function (resolve) { return (_this.resolve = resolve); });
    };
    MaterialNotificationBackend.prototype.onClick = function () {
        this.$mdToast.hide(this.toast);
        this.resolve();
    };
    return MaterialNotificationBackend;
}());
function createMaterialNotificationTemplates($mdToastProvider) {
    $mdToastProvider.addPreset('scNotification', {
        methods: ['content', 'title', 'image', 'icon', 'resolve'],
        options: function () {
            return {
                template: "\n                    <md-toast ng-click=\"$ctrl.resolve()\">\n                        <div class=\"md-toast-content\">\n                            <img class=\"sc-toast-icon\" ng-src=\"{{ $ctrl.icon }}\" ng-if=\"$ctrl.icon\">\n\n                            <div class=\"sc-toast-wrapper\">\n                                <div class=\"md-subhead\" role=\"alert\" aria-relevant=\"all\" aria-atomic=\"true\">\n                                    {{ $ctrl.title }}\n                                </div>\n\n                                {{ $ctrl.content }}\n\n                                <div style=\"max-width: 100px\" ng-if=\"$ctrl.image\">\n                                    <img ng-src=\"{{ $ctrl.image }}\" />\n                                </div>\n                            </div>\n                        </div>\n                    </md-toast>\n                ",
                controller: angular.noop,
                controllerAs: '$ctrl',
                bindToController: true,
                position: 'left bottom',
                hideDelay: 5000,
            };
        },
    });
}
angular
    .module('app.general')
    .config(createMaterialNotificationTemplates)
    .service('NotificationCenter', NotificationCenter)
    .service('NativeNotificationBackend', NativeNotificationBackend)
    .service('MaterialNotificationBackend', MaterialNotificationBackend);
;