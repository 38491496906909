(function () {
    'use strict';
    function AccountTitleController(SPORT_ID) {
        this.transformRoleName = function (role) {
            return role.display;
        };
        this.hasAccountFunctions = function () {
            return (this.account &&
                this.account.functions &&
                this.account.functions.filter(function (item) { return !!item; }).length > 0);
        };
        this.hasHockey = function () {
            var _a;
            return this.account && ((_a = this.account.sports) === null || _a === void 0 ? void 0 : _a.some(function (iter) { return iter.id === SPORT_ID.HOCKEY; }));
        };
        this.hasFootball = function () {
            var _a;
            return this.account && ((_a = this.account.sports) === null || _a === void 0 ? void 0 : _a.some(function (iter) { return iter.id === SPORT_ID.FOOTBALL; }));
        };
    }
    angular.module('app.general').component('scAccountTitle', {
        templateUrl: 'general/components/sc-account-title.html',
        controller: AccountTitleController,
        bindings: { account: '<' },
    });
})();