class ShareWithUsersAutocomplete {
  readonly selections;
  readonly fixedSelections;
  readonly excludeIds;
  private onSelectionChange;

  private context: 'organization' | 'all' = 'organization';
  private searchText = '';

  private currentUser;
  private membersPromise;
  private searchPromise;
  private members;
  private staffMembers;
  private visibleStaffMembers;
  private playerMembers;
  private visiblePlayerMembers;
  private playerMembersByPosition;
  private selectAllState;

  private contactGroups: any[];
  private isUpdatingGroup: boolean;
  private updateGroupErrorMsg: string;
  private editingGroup: any;
  private editingGroupLegacyMembers: any;

  constructor(
    private $rootScope,
    private $q,
    private $filter,
    private $timeout,
    private OrganizationService,
    private UserService,
    private CommunicationAPI,
    private _,
  ) {
    this.currentUser = UserService.getUser();
    this.membersPromise = $q(() => {
      // empty
    });
    this.searchPromise = $q.resolve();
    this.members = [];
    this.staffMembers = [];
    this.playerMembers = [];
    this.playerMembersByPosition = {};
    this.selectAllState = {
      players: false,
      staff: false,
    };

    this.editingGroup = null;
  }

  $onInit() {
    this.loadMembersAndGroups();
  }

  $onChanges(changes) {
    if (changes.selections.currentValue !== changes.selections.previousValue) {
      this._syncState();
    }
  }

  onSelect($selection) {
    if (!this.isSelected($selection)) {
      const selections = (this.selections || []).concat([$selection]);
      this.onSelectionChange({ $selections: selections });
    } else {
      const idx = this.selections.findIndex((iter) => iter._id === $selection._id);
      this.onUnselect($selection, idx);
    }
  }

  onUnselect($selection, $index) {
    const selections = (this.selections || []).filter((item, index) => index !== $index);
    this.onSelectionChange({ $selections: selections });
  }

  select($scTagInstance, item) {
    // if group edit is in-progress
    // select a user will add that user as member of the group
    if (this.editingGroup) {
      const foundIdx = this.editingGroup.members.findIndex((mem) => mem._id === item._id);

      if (foundIdx === -1) {
        this.editingGroup.members = [...this.editingGroup.members, item];
      } else {
        this.editingGroup.members = this.editingGroup.members.filter(
          (iter, idx) => idx !== foundIdx,
        );
      }
    } else {
      // Need this timeout otherwise we entered a race condidation that make the menu close
      // when a member is selected. This is not what we want
      //
      // If we don't have this delay, sometimes the "click-outside" directive event handler
      // will be triggered with the li element (of ng-repeat) that has data-ng-animate=2 (animation is running),
      //
      // and at the time of execution, the li element is detached from the dom, click-outside action will be
      // triggered, causing the autocomplete
      //
      this.$timeout(() => {
        $scTagInstance.select(item);
      });
    }
  }

  selectGroup($scTagInstance, group) {
    const selection = {
      _id: group._id,
      type: 'group',
      display: group.name,
      icon: 'fa fa-users',
    };

    $scTagInstance.select(selection);
  }

  selectAll(users) {
    let selections = this.selections || [];

    const tobeSelected = users.filter((user) => !this.isSelected(user));
    selections = selections.concat(tobeSelected);
    this.onSelectionChange({ $selections: selections });
  }

  selectNone(users) {
    const selections = (this.selections || []).filter(
      (selection) => !users.some((user) => selection._id === user._id),
    );
    this.onSelectionChange({ $selections: selections });
  }

  isSelected(item) {
    if (this.editingGroup) {
      return this.editingGroup.members.some((mem) => mem._id === item._id);
    }

    return (this.selections || []).some((selection) => selection._id === item._id);
  }

  getSelectionCssClass(selection) {
    if (
      selection &&
      selection.type === 'user' &&
      (!selection.organizationId || selection.organizationId !== this.currentUser.account._id)
    ) {
      return 'outside-organization';
    }

    return '';
  }

  setContext(ctx: 'all' | 'organization') {
    this.context = ctx;

    if (this.context === 'all' && this.searchText) {
      this.searchForSuggestions(this.searchText);
    }
  }

  async loadMembersAndGroups() {
    this.membersPromise = this.$q.all([
      this.OrganizationService.listMembers(),
      this.OrganizationService.listContactGroups(),
    ]);

    const [members, contactGroups] = await this.membersPromise;

    this.contactGroups = contactGroups;

    this.members = members || [];

    this._syncState();
  }

  searchForSuggestions(text: string) {
    this.searchText = text;

    // do not call search API if perform the search within organization context
    // (as we already load all member, and the filtering is done in the FE using angular's filter)
    if (this.context === 'organization') {
      // we need to update visibleStaffMembers, visiblePlayerMembers
      this._syncState();
      return;
    }

    if (!text) {
      this.staffSuggestions = [];
      this.playerSuggestions = [];
      return;
    }

    const excludeIds = this.excludeIds || [];
    this.searchPromise = this.CommunicationAPI.searchContacts(text).then(({ users }) => {
      const userRows = (users || [])
        .map((result) => result._source)
        .filter((user) => excludeIds.indexOf(user._id) === -1)
        .map((user) => this._getSuggestionDisplay(user));

      this.playerSuggestions = userRows.filter((user) => {
        const organizationRoles = user.roles.filter((role) => /-organization/.test(role.context));

        return (
          organizationRoles &&
          organizationRoles.length &&
          organizationRoles.every((role) => role.roles.every((r) => r.name === 'player'))
        );
      });

      this.staffSuggestions = _.xorBy(userRows, this.playerSuggestions, '_id');
    });

    return this.searchPromise;
  }

  _syncState() {
    const excludeIds = this.excludeIds || [];
    let members = this.members.filter((user) => excludeIds.indexOf(user._id) === -1);

    if (!this.editingGroup) {
      members = members.filter((user) => user._id !== this.currentUser._id);
    }

    this.staffMembers = members
      .filter((member) => member.roles.length !== 1 || member.roles[0] !== 'Player')
      .map((user) => this._getMemberDisplay(user));
    this.playerMembers = members
      .filter((member) => member.roles.length === 1 && member.roles[0] === 'Player')
      .map((user) => this._getMemberDisplay(user));

    this.visibleStaffMembers = this.searchText
      ? this.$filter('filter')(this.staffMembers, this.searchText)
      : this.staffMembers;

    this.visiblePlayerMembers = this.searchText
      ? this.$filter('filter')(this.playerMembers, this.searchText)
      : this.playerMembers;

    this.playerMembersByPosition = _.groupBy(this.visiblePlayerMembers, 'position');

    Object.keys(this.playerMembersByPosition).forEach((position) => {
      this.selectAllState[position] = this.playerMembersByPosition[position].every((user) =>
        this.isSelected(user),
      );
    });

    this.selectAllState.players = this.playerMembers.every((user) => this.isSelected(user));
    this.selectAllState.staff = this.staffMembers.every((user) => this.isSelected(user));
  }

  _getMemberDisplay(user) {
    const firstName = this._.get(user, 'profile.firstName', '');
    const lastName = this._.get(user, 'profile.lastName', '');
    const jerseyNumber = this._.get(user, 'profile.jerseyNumber', '');
    const position = this._.get(user, 'profile.playerPosition', '');
    const fullName = this._.joinIfPresent(' ', firstName, lastName);

    const displayingUser = {
      _id: this._.get(user, '_id', ''),
      type: 'user',
      organizationId: this.currentUser.account._id,
      roles: (user.roles || []).join(', '),
      display: fullName,
      position,
      jerseyNumber,
    };

    return displayingUser;
  }

  _getSuggestionDisplay(user) {
    const firstName = this._.get(user, 'profile.firstName', '');
    const lastName = this._.get(user, 'profile.lastName', '');
    const fullName = this._.joinIfPresent(' ', firstName, lastName);
    const belongsToCurrentOrg = (user.roles || []).some(
      (role) => role.id && role.id === this.currentUser.account._id,
    );

    return {
      _id: this._.get(user, '_id', ''),
      type: 'user',
      organizationId: belongsToCurrentOrg ? this.currentUser.account._id : null,
      roles: (user.roles || [])
        .filter((role) => !!role.resourceName)
        .map((role) => ({
          ...role,
          displayText:
            role.functions && role.functions.length
              ? role.functions.join(', ')
              : role.roles.map((r) => r.display).join(', '),
        })),
      display: fullName,
    };
  }

  showCreateNewGroup($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.updateGroupErrorMsg = '';
    this.editingGroup = {
      name: '',
      members: [],
    };
    this._syncState();
  }

  cancelCreateNewGroup($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.quitEditingGroup();
  }

  startEditingGroup($event, group) {
    $event.preventDefault();
    $event.stopPropagation();

    this.editingGroup = angular.copy(group);
    this.editingGroupLegacyMembers = group.members.filter((member) => {
      return (
        member._id !== this.currentUser._id &&
        this.staffMembers.findIndex((sug) => sug._id === member._id) === -1 &&
        this.playerMembers.findIndex((sug) => sug._id === member._id) === -1
      );
    });
    this._syncState();
  }

  noLongerInOrganization(member) {
    return this.editingGroupLegacyMembers.some((iter) => iter._id === member._id);
  }

  removeGroupMember($event, member) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!this.editingGroup) {
      return;
    }

    const foundIdx = this.editingGroup.members.indexOf(member);
    this.editingGroup.members = this.editingGroup.members.filter((item, idx) => idx !== foundIdx);
  }

  async confirmEditingGroup($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.updateGroupErrorMsg = '';

    const editingGroup = this.editingGroup;
    const id = editingGroup._id;
    const original = id && this.contactGroups.find((cg) => cg._id === id);

    if (original && angular.equals(editingGroup, original)) {
      this.quitEditingGroup();
      return;
    }

    if (!this.isUpdatingGroup) {
      try {
        this.isUpdatingGroup = true;

        if (id) {
          const updatedGroup = await this.OrganizationService.updateContactGroup(id, editingGroup);
          const oldGroup = this.contactGroups.find((cg) => cg._id === id);

          this.contactGroups = this.contactGroups.map((cg) => (cg._id === id ? updatedGroup : cg));

          const hasGroupNameChanged = oldGroup.name !== updatedGroup.name;

          if (hasGroupNameChanged) {
            if (this.selections && this.selections.length) {
              const selections = this.selections.map((sel) => {
                if (sel._id === updatedGroup._id) {
                  sel.display = updatedGroup.name;
                }
                return sel;
              });

              this.onSelectionChange({ $selections: selections });
            }
            this.$rootScope.$broadcast('contactgroupupdated', updatedGroup);
          }
        } else {
          const newGroup = await this.OrganizationService.createContactGroup(editingGroup);
          this.contactGroups = [newGroup, ...this.contactGroups];
        }

        this.quitEditingGroup();
        this.isUpdatingGroup = false;
      } catch (e) {
        this.updateGroupErrorMsg = (e && e.data && e.data.message) || 'Failed to update group.';
        this.isUpdatingGroup = false;
      }
    }
  }

  async deleteGroup($event, tobeDeleted) {
    $event.preventDefault();
    $event.stopPropagation();

    if (window.confirm('Are you sure you want to delete this group?')) {
      await this.OrganizationService.deleteContactGroup(tobeDeleted._id);

      this.contactGroups = this.contactGroups.filter((cg) => cg._id !== tobeDeleted._id);

      if (this.selections && this.selections.length) {
        const isGroupSelected = this.selections.find((sel) => sel._id === tobeDeleted._id);
        if (isGroupSelected) {
          this.onSelectionChange({
            $selections: this.selections.filter((sel) => sel._id !== tobeDeleted._id),
          });
        }
      }

      this.$rootScope.$broadcast('contactgroupdeleted', tobeDeleted);
      this.quitEditingGroup();
    }
  }

  quitEditingGroup() {
    this.editingGroup = null;
    this.updateGroupErrorMsg = '';
    this.editingGroupLegacyMembers = null;
    this._syncState();
  }
}

angular.module('app.general').component('shareWithUsersAutocomplete', {
  templateUrl: 'general/components/sharing/share-with-users-autocomplete.html',
  controller: ShareWithUsersAutocomplete as any,
  bindings: {
    selections: '<',
    fixedSelections: '<',
    excludeIds: '<',
    placeholder: '@',
    onSelectionChange: '&?',
  },
});
