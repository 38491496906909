/* eslint-disable no-console */
class CommunicationService {
  private $initializationPromise;
  private initializationDefer;
  private state;
  private socket;
  private generalEventChannel;
  private userEventChannel;
  private organizationEventChannels;
  private user;
  private typingLeakyState;
  private inboxStatsLastFetchAt: number;

  private Phoenix;
  private loggingEnabled;
  private wakeTimer;

  private $flowUploaderInstance;

  constructor(
    private $rootScope,
    private $q,
    private $http,
    private SCConfiguration,
    private UserService,
    private CHAT_SOCKET_HOST,
    private ENV,
    private Paginator,
    private LeakyMapFactory,
    private RichTextProcessor,
    private CommunicationMemberCache,
    private ConnectionStatus,
    private WakeUpTimer,
    $window,
  ) {
    this.Phoenix = $window.Phoenix;
    this.state = {};

    this.initializationDefer = $q.defer();
    this.$initializationPromise = this.initializationDefer.promise;
    this.loggingEnabled = ENV === 'development';

    this.WakeUpTimer.onWakeUp(() => this.handleComputerWakeUp());
    this.ConnectionStatus.onChange((isOnline) => this.handleConnectionStatusChange(isOnline));
  }

  init() {
    this.user = this.UserService.getUser();
    const token = this.UserService.getToken();

    this.typingLeakyState = this.LeakyMapFactory.createWithTTL(3000, (key, state) => {
      this.state.typingUsers[key] = state;
    });

    const socket = new this.Phoenix.Socket(`${this.CHAT_SOCKET_HOST}/socket`, {
      params: { token },
      timeout: 3000,
      logger: (kind, msg, data) => {
        this.loggingEnabled && console.log(`${kind}:${msg}`, data);
      },
    });

    socket.connect();

    socket.onOpen((ev) => {
      this.state.isReady = true;
      this.loggingEnabled && console.log('OPEN', ev);

      const existingJoinedChannels =
        this.socket.channels && this.socket.channels.filter((ch) => ch.state === 'joined');
      if (existingJoinedChannels && existingJoinedChannels.length) {
        existingJoinedChannels.forEach((ch) => ch.rejoin());
      }
    });
    socket.onError((ev) => {
      this.state.isReady = false;
      this.loggingEnabled && console.log('ERROR', ev);
    });
    socket.onClose((e) => {
      this.state.isReady = false;
      this.loggingEnabled && console.log('CLOSE', e);
    });

    this.socket = socket;

    this.fetchInboxStats()
      .then((inboxStats) => {
        this.initState(inboxStats);

        const inboxKeys = _.keys(inboxStats);

        this.generalEventChannel = this.findChannelAndJoinIfNeeded('event:general', null);
        this.generalEventChannel.on('presence_state', (state) => {
          this.$rootScope.$apply(() => {
            this.state.presences = this.Phoenix.Presence.syncState(this.state.presences, state);
          });
        });
        this.generalEventChannel.on('presence_diff', (state) => {
          this.$rootScope.$apply(() => {
            this.state.presences = this.Phoenix.Presence.syncDiff(this.state.presences, state);
          });
        });

        this.userEventChannel = this.findChannelAndJoinIfNeeded(
          'event:general:' + this.user._id,
          () => this.$rootScope.$apply(),
        );
        this.userEventChannel.off('channel_created');
        this.userEventChannel.off('channel_rejoined');
        this.userEventChannel.off('new_message');
        this.userEventChannel.off('new_member');
        this.userEventChannel.off('member_left');
        this.userEventChannel.off('message_read');
        this.userEventChannel.on('channel_created', (payload) =>
          this.newChannelCallback(payload, null),
        );
        this.userEventChannel.on('channel_rejoined', (payload) =>
          this.channelRejoinedCallback(payload, null),
        );
        this.userEventChannel.on('new_message', (payload) =>
          this.newMessageCallback(payload, null),
        );
        this.userEventChannel.on('new_member', (payload) => this.newMemberCallback(payload, null));
        this.userEventChannel.on('member_left', (payload) =>
          this.memberLeftCallback(payload, null),
        );
        this.userEventChannel.on('message_read', (payload) =>
          this.messageReadFromOtherDeviceCallback(payload, null),
        );

        this.organizationEventChannels = inboxKeys
          .filter((inboxKey) => inboxKey.indexOf('org:') > -1)
          .map((inboxKey) => {
            const organizationId = inboxKey.split(':')[1];
            const organizationEventChannel = this.findChannelAndJoinIfNeeded(
              'event:organization:' + organizationId,
              () => this.$rootScope.$apply(),
            );
            organizationEventChannel.off('channel_created');
            organizationEventChannel.off('channel_rejoined');
            organizationEventChannel.off('new_message');
            organizationEventChannel.off('new_member');
            organizationEventChannel.off('member_left');
            organizationEventChannel.off('message_read');
            organizationEventChannel.on('channel_created', (payload) =>
              this.newChannelCallback(payload, organizationId),
            );
            organizationEventChannel.on('channel_rejoined', (payload) =>
              this.channelRejoinedCallback(payload, organizationId),
            );
            organizationEventChannel.on('new_message', (payload) =>
              this.newMessageCallback(payload, organizationId),
            );
            organizationEventChannel.on('new_member', (payload) =>
              this.newMemberCallback(payload, organizationId),
            );
            organizationEventChannel.on('member_left', (payload) =>
              this.memberLeftCallback(payload, organizationId),
            );
            organizationEventChannel.on('message_read', (payload) =>
              this.messageReadFromOtherDeviceCallback(payload, null),
            );

            return organizationEventChannel;
          });

        this.initializationDefer.resolve();
      })
      .catch(angular.noop);
  }

  initState(inboxStats) {
    const inboxes = _.mapValues(inboxStats, () => []);
    this.state.inboxes = Immutable.fromJS(inboxes);
    this.state.inboxUnreadCount = inboxStats;
    this.state.currentInbox = '';
    this.state.currentChannel = null;

    this.state.channelMessages = {};
    this.state.hasMoreMessages = {};
    this.state.unreadCount = Immutable.fromJS(_.mapValues(inboxStats, () => ({})));
    this.state.readReceipts = {};
    this.state.typingUsers = {};
    this.state.presences = {};
    this.state.isReady = true;
  }

  registerUploader($flowInstance) {
    this.$flowUploaderInstance = $flowInstance;
  }

  handleComputerWakeUp() {
    if (this.loggingEnabled) console.log('>>> handleComputerWakeUp');

    this.state.isReady = false;

    if (this.socket) {
      this.socket.disconnect(() => this.socket.connect());
    }
  }

  handleConnectionStatusChange(isOnline) {
    if (this.loggingEnabled) console.log('>>> handleConnectionStatusChange', isOnline);

    if (isOnline) {
      if (this.socket) {
        this.socket.connect();
      }
    } else {
      this.socket.disconnect();
    }
  }

  destroy() {
    this.state = {};
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  get isReady(): Boolean {
    return (
      this.$initializationPromise.$$state.status === 1 &&
      this.state.isReady &&
      this.ConnectionStatus.state.isOnline
    );
  }

  get hasInboxUnread(): Boolean {
    return (
      this.state.inboxUnreadCount &&
      Object.keys(this.state.inboxUnreadCount).some((key) => !!this.state.inboxUnreadCount[key])
    );
  }

  selectInbox(inboxId) {
    this.state.currentInbox = inboxId === 'private' ? this.getPrivateInboxKey() : `org:${inboxId}`;
  }

  selectChannel(channel) {
    this.state.currentChannel = channel;
  }

  getPrivateInboxKey() {
    return `user:${this.user._id}`;
  }

  getState() {
    return this.state;
  }

  getChannel(channelId, params) {
    const channelLocations = this.findChannelLocation(channelId);

    if (channelLocations.length) {
      const { inboxKey, index } = channelLocations[0];
      return this.$q.resolve(this.state.inboxes.get(inboxKey).get(index).toJS());
    }

    return this.fetchChannel(channelId, params);
  }

  refreshInboxStats() {
    if (this.inboxStatsLastFetchAt && Date.now() - this.inboxStatsLastFetchAt < 5000) {
      return;
    }

    this.fetchInboxStats()
      .then((inboxStats) => {
        this.state.inboxUnreadCount = inboxStats;
      })
      .catch(angular.noop);
  }

  fetchInboxStats() {
    this.inboxStatsLastFetchAt = Date.now();
    const url = this.SCConfiguration.getEndpoint() + '/api/communication/inbox/stats';
    return this.$http.get(url).then((response) => response.data);
  }

  fetchChannel(channelId, params) {
    const url = this.SCConfiguration.getEndpoint() + '/api/communication/channels/' + channelId;
    return this.$http.get(url, { params }).then((response) => {
      if (response && response.data) {
        this.CommunicationMemberCache.update([response.data]);
        return response.data;
      }
    });
  }

  getInboxes() {
    return this.state.inboxes.toJS();
  }

  getChannelMessages(channelId) {
    return this.state.channelMessages[channelId]
      ? this.state.channelMessages[channelId].toJS()
      : [];
  }

  findMessageChannel(channelId) {
    return this.findChannelAndJoinIfNeeded(`channel:${channelId}`);
  }

  findEventChanel(inboxKey) {
    if (inboxKey.indexOf('org:') > -1) {
      const organizationId = inboxKey.split(':')[1];
      return this.findChannelAndJoinIfNeeded(`event:organization:${organizationId}`);
    }

    return this.userEventChannel;
  }

  findChannelAndJoinIfNeeded(topic, joinCallback = angular.noop) {
    if (!this.socket) {
      console.error('no socket connection. please connect ....');
      return;
    }

    let channel = _.find(this.socket.channels, (ch: any) => ch.topic === topic);

    if (!channel) {
      channel = this.socket.channel(topic, {});
    }

    if (channel.state === 'closed') {
      channel
        .join()
        .receive('ok', (payload) => {
          if (_.isFunction(joinCallback)) {
            joinCallback(null, payload);
          }
        })
        .receive('error', (err) => {
          joinCallback(err, null);
        });
    }

    return channel;
  }

  findChannelLocation(channelId) {
    const locations = [];
    this.state.inboxes.entrySeq().forEach(([key, channels]) => {
      const idx = channels.findIndex((ch) => `${ch.get('id')}` === `${channelId}`);
      if (idx > -1) {
        locations.push({
          inboxKey: key,
          index: idx,
        });
      }
    });

    return locations;
  }

  putChannels(inboxKey, payload = [], shouldConcat) {
    if (!this.isReady) {
      throw new Error('putChannels is not allow prior to initialization');
    }

    const channels = Immutable.fromJS(payload);
    if (shouldConcat) {
      const inboxChannels = this.state.inboxes.get(inboxKey);
      this.state.inboxes = this.state.inboxes.set(inboxKey, inboxChannels.concat(channels));
    } else {
      this.state.inboxes = this.state.inboxes.set(inboxKey, channels);
    }

    payload.forEach(
      (channel) =>
        (this.state.unreadCount = this.state.unreadCount.setIn(
          [inboxKey, channel.id],
          channel.unread_count || 0,
        )),
    );

    return channels;
  }

  subscribeToChannel(channelId) {
    this.userEventChannel.push('add_channel', { channel_id: channelId });
  }

  putMessages(channelId, messages, hasMore) {
    const channelMessages = this.state.channelMessages[channelId] || Immutable.List([]);
    this.state.channelMessages[channelId] = channelMessages.unshift(
      ...messages.map((msg) => this.RichTextProcessor.process(msg)),
    );
    this.state.hasMoreMessages[channelId] = !!hasMore;
    return messages;
  }

  addMessage(channelId, message) {
    this.state.channelMessages[channelId] =
      this.state.channelMessages[channelId] || Immutable.List([]);
    this.state.channelMessages[channelId] = this.state.channelMessages[channelId].push(
      this.RichTextProcessor.process(message),
    );
  }

  removeMessage(channelId, message) {
    this.state.channelMessages[channelId] =
      this.state.channelMessages[channelId] || Immutable.List([]);
    this.state.channelMessages[channelId] = this.state.channelMessages[channelId].filter((msg) =>
      message.client_uuid ? msg.client_uuid !== message.client_uuid : msg.id === message.id,
    );
  }

  markChannelAsRead(inboxKey, channelId) {
    const currentUnreadCount = this.state.unreadCount.getIn([inboxKey, channelId]);
    if (currentUnreadCount) {
      this.decreaseInboxUnreadCount(inboxKey);
    }
    this.state.unreadCount = this.state.unreadCount.setIn([inboxKey, channelId], 0);
  }

  increaseInboxUnreadCount(inboxKey) {
    const currentCount = this.state.inboxUnreadCount[inboxKey] || 0;
    this.state.inboxUnreadCount[inboxKey] = currentCount + 1;
  }

  decreaseInboxUnreadCount(inboxKey) {
    const currentCount = this.state.inboxUnreadCount[inboxKey] || 0;
    if (currentCount > 0) {
      this.state.inboxUnreadCount[inboxKey] = currentCount - 1;
    }
  }

  initChannel(channel: Immutable.Map<string, any>) {
    const socketChannel = this.findChannelAndJoinIfNeeded(
      `channel:${channel.get('id')}`,
      (err, payload) => {
        if (err) {
          throw new Error(err);
        } else {
          this.state.channelMessages[channel.get('id')] = Immutable.List([]);
          this.putMessages(channel.get('id'), payload.messages, payload.has_more);
          this.state.readReceipts[channel.get('id')] = Immutable.List(payload.read_receipts || []);

          // we channel connect successfullt, we assumed that socket connection is fine & ready
          this.state.isReady = true;

          this.$rootScope.$apply();
        }
      },
    );
    socketChannel.off('typing_start');
    socketChannel.off('typing_stop');
    socketChannel.on('typing_start', (payload) => this.typingStartCallback(payload));
    socketChannel.on('typing_stop', (payload) => this.typingStopCallback(payload));

    socketChannel.off('message_read');
    socketChannel.on('message_read', (payload) => this.messageReadCallback(payload));

    return socketChannel;
  }

  newChannelCallback(payload, organizationId) {
    this.$rootScope.$apply(() => {
      const inboxKey = organizationId ? `org:${organizationId}` : `user:${this.user._id}`;
      this.addChannelToInbox(payload, inboxKey, true);
    });
  }

  channelRejoinedCallback(payload, organizationId) {
    const params: any = {};

    if (organizationId) {
      params.organization_id = organizationId;
    }

    return this.getChannel(payload.channel_id, params).then((channel) => {
      const inboxKey = organizationId ? `org:${organizationId}` : `user:${this.user._id}`;
      this.addChannelToInbox(channel, inboxKey, true);
    });
  }

  getInboxKeys(channel) {
    const inboxKeys = [];
    const userId = this.user._id;
    const userOrganizationIds = this.user.accounts
      .filter((acc) => acc.type && acc.type !== 'user')
      .map((acc) => acc._id);

    (channel.organizations || []).forEach((org) => {
      if (userOrganizationIds.indexOf(org.id) > -1) {
        inboxKeys.push(`org:${org.id}`);
      }
    });
    (channel.users || []).forEach((user) => {
      if (user.id === userId) {
        inboxKeys.push(`user:${user.id}`);
      }
    });

    return inboxKeys;
  }

  addChannelToInbox(channel, inboxKey, isNewChannel) {
    const immutableChannel = Immutable.fromJS(channel);
    const inboxChannels = this.state.inboxes.get(inboxKey);

    const index = inboxChannels.findIndex((ch) => ch.get('id') === immutableChannel.get('id'));

    if (index > -1) {
      this.state.inboxes = this.state.inboxes.set(
        inboxKey,
        inboxChannels.set(index, immutableChannel),
      );
    } else {
      this.state.inboxes = this.state.inboxes.set(
        inboxKey,
        inboxChannels.unshift(immutableChannel),
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [inboxType, inboxId] = inboxKey.split(':');
    this.state.unreadCount = this.state.unreadCount.setIn(
      [inboxKey, channel.id],
      channel.unread_count,
    );

    if (isNewChannel) {
      if (channel.unread_count) {
        this.increaseInboxUnreadCount(inboxKey);
      }
      const eventChannel = this.findEventChanel(inboxKey);
      eventChannel.push('add_channel', { channel_id: channel.id });
    }

    return immutableChannel;
  }

  removeChannelFromInbox(channelId, inboxKey) {
    const inboxChannels = this.state.inboxes.get(inboxKey);
    this.state.inboxes = this.state.inboxes.set(
      inboxKey,
      inboxChannels.filter((ch) => ch.get('id') !== channelId),
    );
    if (this.state.unreadCount.getIn([inboxKey, channelId]) > 0) {
      this.decreaseInboxUnreadCount(inboxKey);
    }
    this.state.unreadCount = this.state.unreadCount.setIn([inboxKey, channelId], 0);
    this.state.channelMessages[channelId] = null;

    const eventChannel = this.findEventChanel(inboxKey);
    eventChannel.push('remove_channel', { channel_id: channelId });
  }

  newMessageCallback(payload, organizationId) {
    const channelId = payload.channel_id;

    const channelLocations = this.findChannelLocation(channelId);

    if (!channelLocations.length) {
      const params: any = {};

      if (organizationId) {
        params.organization_id = organizationId;
      }

      return this.getChannel(payload.channel_id, params).then((channel) => {
        const inboxKey = organizationId ? `org:${organizationId}` : `user:${this.user._id}`;
        this.addChannelToInbox(channel, inboxKey, false);
        // need to refresh inbox stats because we can't determine if the channel has unread message or not
        // at the time the last inbox stats is refreshed
        // For eg: if channel already has unread message, we should not increase inboxUnreadCount
        // if channel does not have unread message, and a new message come in, we need to increase inboxUnreadCount
        this.refreshInboxStats();
        this.$rootScope.$broadcast('communication:message:received', {
          message: payload,
          inboxKey,
        });
      });
    }

    const channelMessages = this.state.channelMessages[channelId] || Immutable.List([]);
    const isSystemMessage = payload.type === 0;
    const existing = channelMessages.find(
      (msg) =>
        msg.id === payload.id || (payload.client_uuid && msg.client_uuid === payload.client_uuid),
    );

    if (!existing) {
      this.state.channelMessages[channelId] = channelMessages.push(
        this.RichTextProcessor.process(payload),
      );

      channelLocations.forEach(({ inboxKey }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [inboxType, id] = inboxKey.split(':');
        const isOwnMessage = payload.organization_id
          ? payload.organization_id === id
          : payload.user_id === id;

        if (!isOwnMessage && !isSystemMessage) {
          const currentUnreadCount = this.state.unreadCount.getIn([inboxKey, channelId], 0);
          if (currentUnreadCount === 0) {
            this.increaseInboxUnreadCount(inboxKey);
          }

          this.state.unreadCount = this.state.unreadCount.setIn(
            [inboxKey, channelId],
            currentUnreadCount + 1,
          );
        }
      });
    }

    if (!isSystemMessage) {
      this.updateChannelLastMessage(channelId, payload);
    }

    this.$rootScope.$broadcast('communication:message:received', {
      message: payload,
      inboxKey: _.get(channelLocations[0], 'inboxKey'),
    });
  }

  newMemberCallback(payload, organizationId) {
    // we need to always reload again the channel when new members is added
    // this is the simplest way to solve it rather than sending the new members in the payload
    // (logic is the same for new members & existing members)
    const params = {};
    if (organizationId) {
      params.organization_id = organizationId;
    }
    return this.fetchChannel(payload.channel_id, params).then((channel) => {
      const inboxKey = organizationId ? `org:${organizationId}` : `user:${this.user._id}`;
      this.addChannelToInbox(channel, inboxKey, false);
    });
  }

  memberLeftCallback(payload, _organizationId) {
    const channelLocations = this.findChannelLocation(payload.channel_id);
    if (channelLocations.length) {
      channelLocations.forEach(({ inboxKey, index }) => {
        let inboxChannels = this.state.inboxes.get(inboxKey);
        inboxChannels = inboxChannels.update(index, (channel) => {
          if (payload.user_id) {
            channel = channel.set(
              'users',
              channel.get('users').filter((user) => user.get('id') !== payload.user_id),
            );
          }
          if (payload.organization_id) {
            channel = channel.set(
              'organizations',
              channel
                .get('organizations')
                .filter((org) => org.get('id') !== payload.organization_id),
            );
          }
          return channel;
        });
        this.state.inboxes = this.state.inboxes.set(inboxKey, inboxChannels);
      });
    }
  }

  updateChannelLastMessage(channelId, payload) {
    this.updateChannel(channelId, {
      last_message: payload.text,
      last_message_ts: payload.ts,
    });
  }

  updateChannel(channelId, payload) {
    const channelLocations = this.findChannelLocation(channelId);
    if (channelLocations.length) {
      channelLocations.forEach(({ inboxKey, index }) => {
        let inboxChannels = this.state.inboxes.get(inboxKey);
        inboxChannels = inboxChannels.update(index, (channel) => {
          channel = channel.merge(payload);
          return channel;
        });
        this.state.inboxes = this.state.inboxes.set(inboxKey, inboxChannels);
      });
    }
  }

  typingStartCallback(payload) {
    this.typingLeakyState.add(payload.channel_id, payload);
    this.$rootScope.$broadcast('communication:message:typing_start', payload);
  }

  typingStopCallback(payload) {
    this.typingLeakyState.remove(payload.channel_id, payload);
    this.$rootScope.$broadcast('communication:message:typing_stop', payload);
  }

  messageReadCallback(payload) {
    const channelId = payload.channel_id;
    const channelReadReceipts = this.state.readReceipts[channelId] || Immutable.List([]);
    const idx = channelReadReceipts.findIndex(
      (item) =>
        item.user_id === payload.user_id &&
        (payload.organization_id
          ? item.organization_id === payload.organization_id
          : !item.organization_id),
    );

    if (idx > -1) {
      this.state.readReceipts[channelId] = channelReadReceipts.update(idx, (item) => ({
        ...item,
        ...payload,
      }));
    } else {
      this.state.readReceipts[channelId] = channelReadReceipts.push(payload);
    }

    this.$rootScope.$broadcast('communication:message:read', payload);
  }

  messageReadFromOtherDeviceCallback(payload, organizationId) {
    const inboxKey = organizationId ? `org:${organizationId}` : `user:${this.user._id}`;
    const channelUnreadCount = this.state.unreadCount.getIn([inboxKey, payload.channel_id], 0);

    if (channelUnreadCount) {
      this.state.unreadCount = this.state.unreadCount.setIn([inboxKey, payload.channel_id], 0);
      this.decreaseInboxUnreadCount(inboxKey);
    }
    this.$rootScope.$broadcast('communication:message:read', payload);
  }

  clearDraftMessage(channelId) {
    return localStorage.removeItem(`communication:draft:${channelId}`);
  }

  storeDraftMessage(channelId, message) {
    return localStorage.setItem(`communication:draft:${channelId}`, message);
  }

  retrieveDraftMessage(channelId) {
    return localStorage.getItem(`communication:draft:${channelId}`);
  }
}

angular.module('app.general').service('CommunicationService', CommunicationService);
