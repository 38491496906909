'use strict';

class ConfirmationModalController {
  private modalInstance;
  private message;
  private translatedMessage;
  private modalTitle;

  confirm() {
    this.modalInstance.close();
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}
angular.module('app.general').component('confirmationModal', {
  templateUrl: 'general/components/confirmation-modal.html',
  controller: ConfirmationModalController,
  bindings: {
    modalInstance: '<',
    message: '<',
    translatedMessage: '<',
    modalTitle: '<',
  },
});
