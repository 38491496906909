(function () {
  'use strict';

  angular.module('app.general').directive('scViewAnimation', scViewAnimationDirective);

  var ANIMATION_CLASSES = {
    platform: {
      forward: 'defaultForwardAnim',
      backward: 'defaultBackAnim',
    },
    root: {
      forward: 'defaultDownAnim',
      backward: 'defaultUpAnim',
    },
  };

  var ANIMATION_DURATION = 500; //should be sync with CSS

  var BODY_PADDING = 0;

  var STATES_ORDER = [
    /* platform states */
    { name: 'root.platform.countries', value: 100 },
    { name: 'root.platform.agencies', value: 110 },
    { name: 'root.platform.agency', value: 120 },
    { name: 'root.platform.agent', value: 130 },
    { name: 'root.platform.country', value: 200 },
    { name: 'root.platform.competition', value: 300 },
    { name: 'root.platform.team', value: 400 },
    { name: 'root.platform.player', value: 500 },
    { name: 'root.platform.staff', value: 600 },

    /* root states */
    { name: 'root.communication', value: 2000 },
    { name: 'root.organisation', value: 3000 },
    { name: 'root.tagging', value: 4000 },
    { name: 'root.scouting', value: 5000 },
    { name: 'root.calendar', value: 6000 },
  ];

  function scViewAnimationDirective($timeout) {
    var lastDirections = {
      platform: undefined,
      root: undefined,
    };

    return {
      restrict: 'A',
      scope: {},

      link: function ($scope, $element, $attr) {
        registerAnimationListener($attr.scViewAnimation);
        animateEnter($attr.scViewAnimation);

        $scope.$on('$destroy', performCleanup);
        $element.on('$destroy', resetParentElementHeight);

        function registerAnimationListener(type) {
          $scope.unbindStateListener = $scope.$on(
            '$stateChangeStart',
            function (event, toState, toParams, fromState) {
              var direction = getDirection(fromState, toState, type);

              if ($scope.isAnimatingEnter || $scope.isAnimatingLeave || direction === undefined) {
                return;
              }

              animateLeave(type, direction);
            }
          );
        }

        function animateEnter(type) {
          if (lastDirections[type]) {
            $timeout(function () {
              finishAnimatingEnter();
            }, ANIMATION_DURATION * 1.2);

            startAnimatingEnter();
            setClass(type, lastDirections[type]);
          }
        }

        function animateLeave(type, direction) {
          $timeout(function () {
            finishAnimatingLeave();
          }, ANIMATION_DURATION * 1.2);

          startAnimatingLeave();

          setParentElementHeight();

          setClass(type, direction);
          lastDirections[type] = direction;
        }

        function setClass(type, direction) {
          _.forOwn(ANIMATION_CLASSES[type], function (className) {
            $element.removeClass(className);
          });

          $element.addClass(ANIMATION_CLASSES[type][direction]);
        }

        function startAnimatingEnter() {
          $scope.isAnimatingEnter = true;
        }

        function finishAnimatingEnter() {
          $scope.isAnimatingEnter = false;
        }

        function startAnimatingLeave() {
          $scope.isAnimatingLeave = true;
        }

        function finishAnimatingLeave() {
          $scope.isAnimatingLeave = false;
          resetParentElementHeight();
        }

        function performCleanup() {
          $scope.unbindStateListener();
        }

        function setParentElementHeight() {
          var parentElement = $element.parent(),
            parentTopOffset = parentElement.offset().top;

          parentElement.css('min-height', $(document).height() - parentTopOffset - BODY_PADDING);
        }

        function resetParentElementHeight() {
          $element.parent().css('min-height', 0);
        }
      },
    };

    function getStateWeight(currentStateName) {
      var foundState = _.find(STATES_ORDER, function (stateName) {
        if (currentStateName.indexOf(stateName.name) > -1) {
          return true;
        }

        return false;
      });

      if (typeof foundState === 'undefined') {
        return -1;
      }

      return foundState.value;
    }

    function getDirection(fromState, toState, type) {
      var fromStateOrderWeight = getStateWeight(fromState.name),
        toStateOrderWeight = getStateWeight(toState.name);

      if (type === 'root') {
        fromStateOrderWeight = Math.floor(fromStateOrderWeight / 1000);
        toStateOrderWeight = Math.floor(toStateOrderWeight / 1000);
      }

      return fromStateOrderWeight > toStateOrderWeight ? 'backward' : 'forward';
    }
  }
})();
