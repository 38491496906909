const DownloadJobResource = ($resource, SCConfiguration) => {
  return $resource(
    SCConfiguration.getEndpoint() + '/api/videos/download-jobs/:id/:modifier',
    { id: '@id' },
    {
      getMyDownloads: {
        method: 'GET',
        params: {
          id: 'my',
        },
        isArray: true,
      },
      getMyPendingOrDoneDownloads: {
        method: 'GET',
        params: {
          id: 'my',
          modifier: 'pending-or-done',
        },
        isArray: true,
      },
      retry: {
        method: 'POST',
        url: SCConfiguration.getEndpoint() + '/api/videos/download-jobs/:id/retry',
      },
    },
  );
};

angular.module('app.general').factory('DownloadJobResource', DownloadJobResource);
