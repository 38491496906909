const SLEEP_THREESHOLD = 10000;

class WakeUpTimer {
  private now;
  private callbacks;

  constructor() {
    this.now = Date.now();
    this.callbacks = [];

    setInterval(() => {
      if (Date.now() - this.now > SLEEP_THREESHOLD) {
        this.callbacks.forEach((callback) => callback());
      }

      this.now = Date.now();
    }, 1000);
  }

  onWakeUp(callback) {
    this.callbacks.push(callback);
  }
}

angular.module('app.general').service('WakeUpTimer', WakeUpTimer);
