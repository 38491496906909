class CommunicationAPI {
  private $communicationSocket;
  private $communicationCore;
  private $communicationMemberCache;
  private $userService;
  private $uuid;
  private throttledRead;

  constructor(
    CommunicationService,
    CommunicationMemberCache,
    UserService,
    uuid,
    private SCConfiguration,
    private $http,
  ) {
    this.$communicationSocket = CommunicationService; //TODO: deperate the usage of $communicationSocket in the application
    this.$communicationCore = CommunicationService;
    this.$communicationMemberCache = CommunicationMemberCache;
    this.$userService = UserService;
    this.$uuid = uuid;
    this.throttledRead = {};
  }

  isOrganizationInbox(key) {
    return key && key.indexOf('org:') > -1;
  }

  getOrganizationId(inboxKey) {
    if (this.isOrganizationInbox(inboxKey)) {
      return inboxKey.split(':')[1];
    }
  }

  fetchChannels(inboxKey, params, appendResult) {
    const url = this.SCConfiguration.getEndpoint() + '/api/communication/channels';

    const organizationMatches = inboxKey.match(/^org:([a-z0-9]*)+$/);
    if (organizationMatches && organizationMatches[1]) {
      params.organization_id = organizationMatches[1];
    }

    return this.$http.get(url, { params }).then((response) => {
      this.$communicationCore.putChannels(inboxKey, response.data, appendResult);
      this.$communicationMemberCache.update(response.data);
      return response.data;
    });
  }

  createChannel(inboxKey, users, organizations, subject, message) {
    const organizationId = inboxKey.split('org:')[1];
    if (organizationId) {
      organizations.push({
        id: organizationId,
      });
    }
    const url = this.SCConfiguration.getEndpoint() + '/api/communication/channels/join';
    const params = {
      inbox: inboxKey,
      subject,
      message,
      organizations,
      users,
    };

    // after the successful POST, a websocket event will be broadcast
    // we'll add new channel to the inbox there
    return this.$http.post(url, params).then((response) => response.data);
  }

  leaveChannel(channel, inboxKey) {
    const socketChannel = this.$communicationCore.findMessageChannel(channel.id);
    if (!socketChannel) {
      throw new Error('Channel is not connected channelId=' + channel.id);
    }

    const url = this.SCConfiguration.getEndpoint() + '/api/communication/channels/leave';
    const params: any = {
      channel_id: channel.id,
    };

    if (inboxKey.indexOf('org:') > -1) {
      params.organization_id = inboxKey.split(':')[1];
    }

    return this.$http.post(url, params).then((_response) => {
      socketChannel.leave();
      this.$communicationCore.removeChannelFromInbox(channel.id, inboxKey);
    });
  }

  addMembers(channel, members, canSeeChannelHistory) {
    const users = channel.users.concat(members.filter((item) => item.type === 'user'));
    const organizations = channel.organizations.concat(
      members.filter((item) => item.type !== 'user'),
    );

    if (canSeeChannelHistory) {
      const url =
        this.SCConfiguration.getEndpoint() +
        `/api/communication/channels/${channel.id}/add_members`;

      const data: any = {
        users,
        organizations,
      };

      return this.$http.post(url, data).then((channel) => {
        this.$communicationCore.updateChannel(channel.id, channel);
      });
    } else {
      const inboxKey = this.$communicationCore.getState().currentInbox;
      this.createChannel(inboxKey, users, organizations, channel.name, 'Welcome to new channel!');
    }
  }

  getOlderMessages(channelId) {
    const channelMessages = this.$communicationCore.getChannelMessages(channelId);
    const params: any = {};
    if (channelMessages && channelMessages.length) {
      const oldestMessage = channelMessages[0];
      params.before = oldestMessage.ts;
    }

    const url =
      this.SCConfiguration.getEndpoint() + `/api/communication/channels/${channelId}/messages`;

    return this.$http.get(url, { params }).then((response) => {
      this.$communicationCore.putMessages(
        channelId,
        response.data.messages,
        response.data.has_more,
      );
    });
  }

  sendMessage(channel, text, inboxKey) {
    const organizationId = inboxKey.split('org:')[1];
    if (
      organizationId &&
      (!channel.organizations ||
        !channel.organizations.length ||
        !_.find(channel.organizations, { id: organizationId }))
    ) {
      throw new Error(
        'Can not send onbehalf of organization ' + organizationId + ' in this channel',
      );
    }

    const socketChannel = this.$communicationCore.findMessageChannel(channel.id);
    if (!socketChannel) {
      throw new Error('SendMessage: Channel is not connected channelId=' + channel.id);
    }

    const clientUUID = this.$uuid.v4();
    const request = socketChannel.push('new_message', {
      text,
      organization_id: organizationId,
      client_uuid: clientUUID,
    });
    const message = {
      client_uuid: clientUUID,
      user_id: this.$userService.getUser()._id,
      text,
      organization_id: organizationId,
      $failed: false,
      $sent: false,
    };

    request.receive('ok', (data) => {
      _.assign(message, data, { $sent: true });
    });

    request.receive('error', () => (message.$failed = true));
    request.receive('timeout', () => (message.$failed = true));

    this.$communicationCore.addMessage(channel.id, message);
    return message;
  }

  initAttachment(channel, inboxKey, $file) {
    const organizationId = inboxKey.split('org:')[1];
    if (
      organizationId &&
      (!channel.organizations ||
        !channel.organizations.length ||
        !_.find(channel.organizations, { id: organizationId }))
    ) {
      throw new Error(
        'Can not send onbehalf of organization ' + organizationId + ' in this channel',
      );
    }

    const clientUUID = this.$uuid.v4();
    const message = {
      client_uuid: clientUUID,
      user_id: this.$userService.getUser()._id,
      attachments: [
        {
          name: $file.name,
          size: $file.size,
          type: $file.file.type || 'unknown',
        },
      ],
      organization_id: organizationId,
      $file,
      $failed: false,
      $sent: false,
    };

    this.$communicationCore.addMessage(channel.id, message);
    return message;
  }

  cancelAttachmentMessage(channelId, inboxKey, message) {
    this.$communicationCore.removeMessage(channelId, message);
  }

  sendAttachmentMessage(channelId, inboxKey, message) {
    const socketChannel = this.$communicationCore.findMessageChannel(channelId);
    if (!socketChannel) {
      throw new Error('SendMessage: Channel is not connected channelId=' + channelId);
    }

    const isPhoto = !!message.attachments[0].thumb_url;
    const text = isPhoto ? 'Photo' : `File: ${message.attachments[0].name}`;

    const request = socketChannel.push('new_message', {
      text,
      organization_id: message.organization_id,
      client_uuid: message.client_uuid,
      attachments: message.attachments,
    });

    request.receive('ok', (data) => {
      _.assign(message, data, { $sent: true });
    });

    request.receive('error', () => (message.$failed = true));
    request.receive('timeout', () => (message.$failed = true));
  }

  notifyAboutTyping(channel, inboxKey, isTyping) {
    const organizationId = inboxKey.split('org:')[1];
    const socketChannel = this.$communicationCore.findMessageChannel(channel.id);

    if (!socketChannel) {
      throw new Error('notifyAboutTyping: Channel is not connected channelId=' + channel.id);
    }

    const payload: any = {};

    if (organizationId) {
      payload.organization_id = organizationId;
    }

    if (isTyping) {
      socketChannel.push('typing_start', payload);
    } else {
      socketChannel.push('typing_stop', payload);
    }
  }

  markRead(channelId, inboxKey, message) {
    const socketChannel = this.$communicationCore.findMessageChannel(channelId);
    if (!socketChannel) {
      throw new Error('_markRead: Channel is not connected channelId=' + channelId);
    }

    const payload: any = { ts: message.ts };
    if (this.isOrganizationInbox(inboxKey)) {
      payload.organization_id = this.getOrganizationId(inboxKey);
    }

    this.$communicationCore.markChannelAsRead(inboxKey, channelId);
    socketChannel.push('message_read', payload);
  }

  searchContacts(text) {
    const url = this.SCConfiguration.getEndpoint() + `/api/communication/search`;
    return this.$http.get(`${url}?q=${text}`).then((res) => res.data);
  }
}

angular.module('app.general').service('CommunicationAPI', CommunicationAPI);
