import {
  Representation,
  RepresentationConflict,
} from '../../profile/services/RepresentationService';

class UserTasksHelper {
  constructor(private $uibModal) {}

  handleRepresentationChanges(unconfirmed: Representation[], conflicts: RepresentationConflict[]) {
    const modal = this.$uibModal.open({
      template: `<modal-player-representation-changes modal-instance="$ctrl.modalInstance" unconfirmed="$ctrl.unconfirmed" conflicts="$ctrl.conflicts">`,
      controllerAs: '$ctrl',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.unconfirmed = unconfirmed;
          this.conflicts = conflicts;
        },
      ],
    });

    modal.result.catch(angular.noop);
  }

  handleTOSUpdate(lastUpdated: string) {
    const modal = this.$uibModal.open({
      template: `<modal-accept-tos modal-instance="$ctrl.modalInstance" tos-last-updated="$ctrl.lastUpdated">`,
      backdrop: 'static', // prevent close on clicking background
      keyboard: false, // prevent esc button
      controllerAs: '$ctrl',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.lastUpdated = lastUpdated;
        },
      ],
    });

    modal.result.catch(angular.noop);
  }
}

angular.module('app.general').service('UserTasksHelper', UserTasksHelper as any);
