angular.module('app.general').factory('MissingTranslationReporter', MissingTranslationReporter);

function MissingTranslationReporter() {
  const ALREADY_REPORTED_TRANSLATIONS = new Set();

  return (translation) => {
    if (!ALREADY_REPORTED_TRANSLATIONS.has(translation)) {
      // eslint-disable-next-line no-console
      console.error(`Undefined translation: "${translation}"`);
      ALREADY_REPORTED_TRANSLATIONS.add(translation);
    }
  };
}
