class AcceptTosController {
  private modalInstance;
  private accepted: boolean;
  private accepting: boolean;
  private tosLastUpdated: string;

  constructor(
    private $http,
    private SCConfiguration,
  ) {}

  async accept() {
    if (!this.accepted) {
      return;
    }

    this.accepting = true;

    try {
      await this.$http.post(this.SCConfiguration.getEndpoint() + '/api/users/tos', {
        tosVersion: this.tosLastUpdated,
        accepted: true,
      });

      this.accepting = false;

      this.modalInstance.close('ok');
    } catch (_e) {
      this.accepting = false;
    }
  }
}

angular.module('app.general').component('modalAcceptTos', {
  templateUrl: 'general/modals/accept-tos.html',
  controller: AcceptTosController as any,
  bindings: {
    modalInstance: '<',
    tosLastUpdated: '<',
  },
});
