// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Memoized(_target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalGet = descriptor.get;

  descriptor.get = function () {
    // eslint-disable-next-line no-prototype-builtins
    if (!this.hasOwnProperty('__memoized__')) {
      Object.defineProperty(this, '__memoized__', { value: new Map() });
    }

    return this.__memoized__.has(propertyKey)
      ? this.__memoized__.get(propertyKey)
      : (() => {
          const value = originalGet.call(this);
          this.__memoized__.set(propertyKey, value);
          return value;
        })();
  };
}
